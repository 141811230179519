import React, { useCallback } from 'react';

import { useAuth } from '@8base-react/auth';
import styled from '@emotion/styled';
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@mui/material';

import { processEnv } from '@builder/utils';

import { AUTH_KEY } from '../../../shared/constants';
import { DASHBOARD_DIALOGS } from '../dialogsMap';
import { useCookieStorage, useDialogState } from 'src/providers';
import { Button } from 'src/shared/components';

const DIALOG_ID = DASHBOARD_DIALOGS.NOT_AUTHTORIZED_DIALOG_ID;

const DialogActionsStyled = styled(DialogActions)`
  gap: ${({ theme }) => theme.spacing(1)};
`;

export const NotAuthorizedDialog: React.FC = () => {
  const { authClient } = useAuth();
  const { CookieStorage } = useCookieStorage();
  const { isOpen } = useDialogState(DIALOG_ID);

  const logout = useCallback(() => {
    authClient.purgeState();
    CookieStorage.removeItem(AUTH_KEY);
    CookieStorage.setItem(AUTH_KEY, '{}');
    window.location.reload();
  }, [CookieStorage, authClient]);

  return (
    <Dialog open={isOpen}>
      <DialogTitle>Warning</DialogTitle>
      <DialogContent>
        <DialogContentText>You do not have the access to the workspace?</DialogContentText>
      </DialogContent>
      <DialogActionsStyled>
        <Button variant="contained" color="default" onClick={logout}>
          Logout
        </Button>
        <Button variant="contained" color="success" href={processEnv.getConsoleURL()}>
          Go Back To The Developer Home
        </Button>
      </DialogActionsStyled>
    </Dialog>
  );
};
