import Cookies from 'js-cookie';

export class CookieStorage {
  private readonly mainDomain: string;

  constructor(customDomain?: string) {
    this.mainDomain = customDomain || this.getCurrentDomain();
  }

  private getCurrentDomain(): string {
    const parts = window.location.hostname.split('.');
    return parts.length > 2 ? parts.slice(1).join('.') : parts.join('.');
  }

  public getItem(storageKey: string): string | undefined {
    const item = Cookies.get(storageKey);
    return item || undefined;
  }

  public setItem(storageKey: string, value: string): void {
    Cookies.set(storageKey, value, { domain: this.mainDomain, sameSite: 'None', secure: true });
  }

  public removeItem(storageKey: string): void {
    Cookies.remove(storageKey);
  }
}
