var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
/* eslint-disable max-classes-per-file */
import { Fragment } from 'react';
import { mergeDeepRight } from 'ramda';
import { matchPath } from 'react-router-dom';
import { COMPONENT_DSL_TYPES, componentListSelectors, nodeListSelectors, nodeSelectors, COMPONENT_DSL_KINDS, COMPONENT_DSL_NAMES, createRenderPathData, getComponentDataProps, appNodesSchemaSelectors, appSettingsSelectors, PropAssert, } from '@builder/schemas';
import { ERROR_SCOPES, isNull, isObject, isString, SystemError } from '@builder/utils';
import { getReactComponentByName } from '../getReactComponentByName';
import { Presentation, PresentationsContainer } from '../node-presentations';
import { transformCondition } from '../node-transformers';
import { transformNodeProps, NodeErrorGetter } from '../props-transformers';
import { NodeDSLToReactRenderer } from './NodeDSLToReactRenderer';
/**
 * Recursively generates react nodes from dsl nodes.
 */
class NodeDSLToReactTransformerBase {
    constructor(options) {
        this.presentationsContainer = new PresentationsContainer();
        this.options = options;
    }
    /**
     * Sets initializer for function which creates React node by node DSL.
     * Is used to create children nodes.
     */
    init(initGenerateNodeTree) {
        this.initGenerateNodeTree = initGenerateNodeTree;
    }
    /**
     * Wrapper to prevent usage without initialized generateNodeTree callback.
     */
    generateNodeTree(nodeDSL, externalState, renderPathData, baseDSLToControlRef) {
        if (!this.initGenerateNodeTree) {
            throw new SystemError(ERROR_SCOPES.appEngine, `initGenerateNodeTree callback must be specified`);
        }
        return this.initGenerateNodeTree(nodeDSL, externalState, renderPathData, baseDSLToControlRef);
    }
    /**
     * @returns Router location pathname.
     */
    getLocationPathname(externalState) {
        const { location } = externalState.predefinedState;
        if (!isObject(location) || !isString(location.pathname)) {
            throw new SystemError(ERROR_SCOPES.appEngine, `Location pathname wasn't found`);
        }
        return location.pathname;
    }
    /**
     * @returns specified component DSL.
     */
    getComponentSchema(componentName) {
        const { componentListDSL } = this.options;
        const componentDSL = componentListSelectors.getComponentDSL(componentListDSL, {
            componentName,
        });
        return componentDSL;
    }
    /**
     * @returns React component which must be rendered.
     */
    getReactComponent(componentName) {
        const { componentListDSL } = this.options;
        const ReactComponent = getReactComponentByName(componentListDSL, componentName);
        return ReactComponent;
    }
    /**
     * @returns Condition for specified node. If false then node mustn't render.
     */
    transformCondition(nodeWithCondition, externalState) {
        var _a, _b;
        const { onAppAuditNotifications } = this.options;
        const conditionRule = (_b = (_a = nodeWithCondition.condition) === null || _a === void 0 ? void 0 : _a.showIf) !== null && _b !== void 0 ? _b : null;
        const errorGetter = new NodeErrorGetter(nodeWithCondition, onAppAuditNotifications);
        const transformedCondition = transformCondition(conditionRule, externalState, errorGetter);
        return transformedCondition;
    }
    /**
     * Transforms props DSL to js object with props.
     * @returns Object with transformed props.
     * @example
     * { name: 'Button', props: { text: '{{ someValue }}' } => { text: 'Button Text' }
     */
    transformNodeProps(nodeDSL, externalState, renderPathData, baseDSLToControlRef) {
        const { nodeListDSL, componentListDSL, assetListDSL, onAppAuditNotifications, libraries, } = this.options;
        const transformedProps = transformNodeProps({
            nodeDSL,
            componentListDSL,
            assetListDSL,
            nodeListDSL,
        }, externalState, {
            generateNode: (nodeID, propState, pathToUniqueKey) => {
                const tree = this.generateNodeTree(nodeListDSL[nodeID], propState, createRenderPathData({
                    nodeID,
                    parentRenderPath: renderPathData,
                    iterationSuffix: pathToUniqueKey,
                }), baseDSLToControlRef);
                return tree;
            },
            onAppAuditNotifications,
        }, libraries);
        return transformedProps;
    }
    /**
     * @returns True if route node match the pathname.
     */
    matchRouteNodeWithLocation(nodeDSL, externalState) {
        const pathname = this.getLocationPathname(externalState);
        return nodeSelectors.matchRouteNodePath(nodeDSL, pathname);
    }
    transformLayoutNodePath(nodeDSL) {
        const { nodeListDSL, componentListDSL } = this.options;
        const allChildrenRouteNodes = nodeListSelectors.getAllChildrenRouteNodes(nodeListDSL, {
            nodeID: nodeDSL.id,
            componentListDSL,
        });
        return Object.assign(Object.assign({}, nodeDSL.props), { path: allChildrenRouteNodes.map(node => node.props.path), exact: true });
    }
    /**
     * Adds addiction logic before regular prop transformation.
     * Path is overrode to pass all children routes inside them.
     */
    beforeLayoutKindPropTransformation(nodeDSL) {
        return this.transformLayoutNodePath(nodeDSL);
    }
    transformRouteNodePath(nodeDSL) {
        const { nodeListDSL, componentListDSL } = this.options;
        const allChildrenRouteNodes = nodeListSelectors.getAllChildrenRouteNodes(nodeListDSL, {
            nodeID: nodeDSL.id,
            componentListDSL,
        });
        return Object.assign(Object.assign({}, nodeDSL.props), { path: [nodeDSL.props.path, ...allChildrenRouteNodes.map(node => node.props.path)], exact: true });
    }
    /**
     * Adds addiction logic before regular prop transformation.
     * Path is overrode to pass all children routes inside them.
     */
    beforeRouteKindPropTransformation(nodeDSL) {
        return this.transformRouteNodePath(nodeDSL);
    }
    /**
     * Adds min size styles before regular prop transformation.
     */
    beforeStylePropTransformation(nodeDSL) {
        var _a;
        const { nodeListDSL, componentListDSL, appSettings } = this.options;
        const appDSL = { nodeListDSL, componentListDSL };
        const minSizeStyleProp = appNodesSchemaSelectors.getMinSizeStyles(appDSL, {
            nodeID: nodeDSL.id,
        });
        const isNodeEmpty = appNodesSchemaSelectors.isNodeEmpty(appDSL, {
            nodeID: nodeDSL.id,
        });
        const shouldMinSizeBeAppliedInAppEngine = appSettingsSelectors.shouldMinSizeBeAppliedInAppEngine(appSettings);
        if (isNodeEmpty && shouldMinSizeBeAppliedInAppEngine && isObject(minSizeStyleProp)) {
            const beforeStyle = (_a = nodeDSL.props.style) !== null && _a !== void 0 ? _a : {};
            PropAssert.Value.assertIsObjectProp(beforeStyle);
            return Object.assign(Object.assign({}, nodeDSL.props), { style: Object.assign(Object.assign({}, minSizeStyleProp), beforeStyle) });
        }
        return nodeDSL.props;
    }
    /**
     * @returns State which should be added to node state
     */
    enhanceNodeState(nodeDSL, externalState) {
        var _a;
        const componentDSL = this.getComponentSchema(nodeDSL.name);
        if (componentDSL.kind === COMPONENT_DSL_KINDS.route) {
            const match = this.matchRouteNodeWithLocation(nodeDSL, externalState);
            if (!match) {
                return {
                    params: {},
                    route: {},
                };
            }
            const { path } = match, route = __rest(match, ["path"]);
            return {
                params: (_a = match === null || match === void 0 ? void 0 : match.params) !== null && _a !== void 0 ? _a : {},
                route: Object.assign({}, route),
                matchPath,
            };
        }
        return {};
    }
    /**
     * Applies presentation to the condition and checks if it already was applied or not.
     */
    applyPresentationToCondition(nodeDSL, transformedCondition) {
        var _a;
        const { skipPresentations } = this.options;
        const componentDSL = this.getComponentSchema(nodeDSL.name);
        if (skipPresentations) {
            return transformedCondition;
        }
        let transformedConditionAfterApply = transformedCondition;
        (_a = componentDSL.schema.presentations) === null || _a === void 0 ? void 0 : _a.forEach(presentationDSL => {
            var _a;
            const isPresentationApplied = nodeSelectors.isPresentationApplied(nodeDSL, {
                presentationName: presentationDSL.name,
            });
            this.presentationsContainer
                .getPresentation(nodeDSL.id)
                .correspondWithConditionBeforePresentationOverride(transformedCondition);
            if (isPresentationApplied && (presentationDSL === null || presentationDSL === void 0 ? void 0 : presentationDSL.condition)) {
                transformedConditionAfterApply = ((_a = presentationDSL.condition) === null || _a === void 0 ? void 0 : _a.showIf) || transformedCondition;
            }
            this.presentationsContainer
                .getPresentation(nodeDSL.id)
                .correspondWithConditionAfterPresentationOverride(transformedConditionAfterApply);
        });
        return transformedConditionAfterApply;
    }
    /**
     * Applies presentation to the props and checks if it already was applied or not.
     */
    applyPresentationToProps(nodeDSL, transformedProps) {
        var _a;
        const { skipPresentations } = this.options;
        const componentDSL = this.getComponentSchema(nodeDSL.name);
        if (skipPresentations) {
            return transformedProps;
        }
        let transformedPropsAfterApply = transformedProps;
        (_a = componentDSL.schema.presentations) === null || _a === void 0 ? void 0 : _a.forEach(presentationDSL => {
            const isPresentationApplied = nodeSelectors.isPresentationApplied(nodeDSL, {
                presentationName: presentationDSL.name,
            });
            this.presentationsContainer
                .getPresentation(nodeDSL.id)
                .correspondWithPropsBeforePresentationOverride(transformedProps);
            // Adds extra props to the DSL node props before transformation
            if (isPresentationApplied && presentationDSL.props) {
                transformedPropsAfterApply = mergeDeepRight(transformedProps, presentationDSL.props);
            }
            this.presentationsContainer
                .getPresentation(nodeDSL.id)
                .correspondWithPropsAfterPresentationOverride(transformedPropsAfterApply);
        });
        return transformedPropsAfterApply;
    }
    /**
     * Generates pairs of react component and props for simple node.
     */
    generateBasicNode(nodeDSL, externalState, renderPathData, baseDSLToControlRef) {
        const ReactComponent = this.getReactComponent(nodeDSL.name);
        const overrideExternalState = externalState;
        const transformedProps = this.applyPresentationToProps(nodeDSL, this.transformNodeProps(nodeDSL, overrideExternalState, renderPathData, baseDSLToControlRef));
        return [{ ReactComponent, transformedProps, renderPathData, nodeDSL }];
    }
    /**
     * Generates pairs of react component and props for symbol node.
     */
    generateSymbolNode(nodeDSL, externalState, renderPathData, baseDSLControlRef) {
        const componentDSL = this.getComponentSchema(nodeDSL.name);
        const rootSymbolNode = nodeListSelectors.getRootNodeDSL(componentDSL.schema.symbolNodes);
        const isDialog = nodeDSL.name === 'LocalDialogSymbol' || nodeDSL.name === 'DialogSymbol';
        const { presentationStates } = this.options;
        if (isDialog && !presentationStates[nodeDSL.id]) {
            return [
                {
                    nodeDSL,
                    ReactComponent: null,
                    transformedProps: null,
                    renderPathData: null,
                },
            ];
        }
        const symbolProps = this.applyPresentationToProps(nodeDSL, this.transformNodeProps(nodeDSL, externalState, renderPathData, baseDSLControlRef));
        if (isDialog && presentationStates[nodeDSL.id]) {
            symbolProps.dialogProps.open = true;
        }
        const symbolRuntimeState = Object.assign(Object.assign({}, externalState), { localState: Object.assign(Object.assign({}, externalState.localState), { symbolProps }) });
        const symbolNodeTransformer = new SymbolDSLToReactTransformer(Object.assign(Object.assign({}, this.options), { nodeListDSL: componentDSL.schema.symbolNodes, appStates: {}, symbolNodeDSL: nodeDSL, libraries: this.options.libraries }));
        const nodeDSLToReactRenderer = new NodeDSLToReactRenderer(Object.assign(Object.assign({}, this.options), { nodeListDSL: componentDSL.schema.symbolNodes, appStates: {} }), symbolNodeTransformer, this.options.libraries);
        nodeDSLToReactRenderer.init();
        return symbolNodeTransformer.generateReactComponentWithProps(rootSymbolNode, symbolRuntimeState, renderPathData, {}, presentationStates, baseDSLControlRef);
    }
    /**
     * Generates pairs of React component and props for them based on its type.
     */
    generateNodeByType(nodeDSL, externalState, renderPathData, baseDSLControlRef) {
        const componentDSL = this.getComponentSchema(nodeDSL.name);
        const enhancedLocalState = this.enhanceNodeState(nodeDSL, externalState);
        const enhancedExternalState = Object.assign(Object.assign({}, externalState), { localState: Object.assign(Object.assign({}, externalState.localState), enhancedLocalState) });
        if (componentDSL.type === COMPONENT_DSL_TYPES.symbol) {
            return this.generateSymbolNode(nodeDSL, enhancedExternalState, renderPathData, baseDSLControlRef);
        }
        const basicNode = this.generateBasicNode(nodeDSL, enhancedExternalState, renderPathData, baseDSLControlRef);
        return basicNode;
    }
    /**
     * Adds data props to the final react node.
     */
    addDataProps(nodeDSL, generatedNodes, renderPathData) {
        const generatedNodesWithDataProps = generatedNodes.reduce(function mixinDataProps(acc, node) {
            if (node.ReactComponent === Fragment) {
                // Symbol('react.fragment') === Symbol('react.fragment')
                // fragments cannot hold additional properties and React will complain
                return [...acc, node];
            }
            const nodeWithDataProps = Object.assign(Object.assign({}, node), { transformedProps: Object.assign(Object.assign({}, node.transformedProps), getComponentDataProps({
                    nodeID: nodeDSL.id,
                    nodeName: nodeDSL.name,
                    renderPathData,
                })) });
            return [...acc, nodeWithDataProps];
        }, []);
        return generatedNodesWithDataProps;
    }
    /**
     * Checks if AppEngine should skip processing node and it's children
     * for example, skip other routes if they aren't shown
     */
    shouldBeSkipped(nodeDSL, externalState) {
        if (nodeDSL.name === COMPONENT_DSL_NAMES.BuilderComponentsRoute ||
            nodeDSL.name === COMPONENT_DSL_NAMES.BuilderComponentsRouteForLayout) {
            const enhancedNodeRoutePropsDSL = this.beforeRouteKindPropTransformation(nodeDSL);
            const enhancedNodeRouteDSL = Object.assign(Object.assign({}, nodeDSL), { props: enhancedNodeRoutePropsDSL });
            const isNotCurrentRoute = !this.matchRouteNodeWithLocation(enhancedNodeRouteDSL, externalState);
            if (isNotCurrentRoute) {
                return true;
            }
        }
        return false;
    }
    havePresentationState(nodeID, presentationStates) {
        return Object.entries(presentationStates).some(([id, state]) => id === nodeID && state);
    }
    /**
     * Generates pairs of React component and props for them.
     */
    generateReactComponentWithProps(nodeDSL, externalState, renderPathData, libraries, presentationStates, baseDSLControlRef) {
        const enhancedLocalState = this.enhanceNodeState(nodeDSL, externalState);
        const enhancedExternalState = Object.assign(Object.assign({}, externalState), { localState: Object.assign(Object.assign({}, externalState.localState), enhancedLocalState) });
        this.presentationsContainer.addPresentation(nodeDSL.id, new Presentation({
            componentListDSL: this.options.componentListDSL,
            nodeDSL,
        }));
        const transformedCondition = this.applyPresentationToCondition(nodeDSL, this.transformCondition(nodeDSL, externalState));
        const generatedNodes = this.generateNodeByType(nodeDSL, enhancedExternalState, renderPathData, baseDSLControlRef);
        const generatedNodesWithDataProps = this.addDataProps(nodeDSL, generatedNodes, renderPathData);
        const isVisibleInCanvas = this.havePresentationState(nodeDSL.id, presentationStates);
        if (!isVisibleInCanvas && (!transformedCondition || isNull(generatedNodesWithDataProps))) {
            return [
                {
                    ReactComponent: null,
                    transformedProps: null,
                    renderPathData: null,
                    nodeDSL,
                },
            ];
        }
        return generatedNodesWithDataProps;
    }
}
export class NodeDSLToReactTransformer extends NodeDSLToReactTransformerBase {
    beforePropTransformation(nodeDSL, componentDSL) {
        if (componentDSL.kind === COMPONENT_DSL_KINDS.layout) {
            return Object.assign(Object.assign({}, nodeDSL), { props: Object.assign(Object.assign({}, nodeDSL.props), this.beforeLayoutKindPropTransformation(nodeDSL)) });
        }
        if (componentDSL.kind === COMPONENT_DSL_KINDS.route) {
            return Object.assign(Object.assign({}, nodeDSL), { props: Object.assign(Object.assign({}, nodeDSL.props), this.beforeRouteKindPropTransformation(nodeDSL)) });
        }
        return Object.assign(Object.assign({}, nodeDSL), { props: Object.assign(Object.assign({}, nodeDSL.props), this.beforeStylePropTransformation(nodeDSL)) });
    }
    generateReactComponentWithProps(nodeDSL, externalState, renderPathData, libraries, presentationStates, baseDSLControlRef) {
        const componentDSL = this.getComponentSchema(nodeDSL.name);
        const transformedNodeDSL = this.beforePropTransformation(nodeDSL, componentDSL);
        return super.generateReactComponentWithProps(transformedNodeDSL, externalState, renderPathData, libraries, presentationStates, baseDSLControlRef);
    }
}
export class SymbolDSLToReactTransformer extends NodeDSLToReactTransformerBase {
    constructor(_a) {
        var { symbolNodeDSL } = _a, rest = __rest(_a, ["symbolNodeDSL"]);
        super(rest);
        this.symbolNodeDSL = symbolNodeDSL;
    }
    generateBasicNode(nodeDSL, externalState, renderPathData, baseDSLControlRef) {
        let overrideExternalState = externalState;
        if (nodeDSL.name === COMPONENT_DSL_NAMES.Fragment &&
            this.symbolNodeDSL.name === COMPONENT_DSL_NAMES.Iterator) {
            overrideExternalState = Object.assign(Object.assign({}, overrideExternalState), { localState: Object.assign({}, overrideExternalState.localState) });
        }
        return super.generateBasicNode(nodeDSL, overrideExternalState, renderPathData, baseDSLControlRef);
    }
}
