var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { forwardRef } from 'react';
import ReactHtmlParser from 'react-html-parser';
export const RichText = forwardRef((_a, ref) => {
    // we need to enforce overflow to force `div` to include inner margins,
    // otherwise those will collapse by default & we wont be able to size overlays accurately
    var { style = {}, id, title, className, lang, translate, content } = _a, rest = __rest(_a, ["style", "id", "title", "className", "lang", "translate", "content"]);
    return (_jsx("div", Object.assign({ ref: ref }, rest, { style: Object.assign(Object.assign({}, style), { overflow: 'auto' }), id: id, title: title, className: className, lang: lang, translate: translate }, { children: content ? ReactHtmlParser(content) : null })));
});
