import React from 'react';

import { AuthProvider as BaseAuthProvider } from '@8base-react/auth';
import { Auth, AUTH_STRATEGIES, ISubscribableAuthClient } from '@8base/auth';
import { IStorage } from '@8base/utils';
import { useRollbarPerson } from '@rollbar/react';

import { processEnv } from '@builder/utils';

import { useCookieStorage } from '../CookieStoreProvider';
import { APP_URL, AUTH_KEY, IS_WEB_AUTH0 } from 'src/shared/constants';

export const AuthProvider: React.FC = ({ children }) => {
  const { CookieStorage, getCookieStorage } = useCookieStorage();
  const { token } = getCookieStorage();

  const authClient = Auth.createClient(
    {
      strategy: IS_WEB_AUTH0 ? AUTH_STRATEGIES.WEB_AUTH0 : AUTH_STRATEGIES.API_TOKEN,
      storageOptions: IS_WEB_AUTH0
        ? undefined
        : {
            storageKey: AUTH_KEY,
            storage: (CookieStorage as unknown) as IStorage,
          },
      subscribable: true,
    },
    {
      apiToken: token || undefined,
      useStorageAPIToken: IS_WEB_AUTH0 ? undefined : true,
      domain: processEnv.getAuthDomain(),
      clientId: processEnv.getAuthClientID(),
      redirectUri: `${window.location.origin}${APP_URL.public.auth.callback}`,
      logoutRedirectUri: `${window.location.origin}${APP_URL.public.auth.root}`,
    },
  ) as ISubscribableAuthClient;

  useRollbarPerson(authClient.getState());

  return <BaseAuthProvider authClient={authClient}>{children}</BaseAuthProvider>;
};
