import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { autocompletion, Completion } from '@codemirror/autocomplete';
import { javascript } from '@codemirror/lang-javascript';
import { EditorState, Extension, Compartment } from '@codemirror/state';
import { EditorView } from '@codemirror/view';

import customCompletions from './CodeMIrror.Autocomplete';
import { basicDark } from './CodeMirror.theme';

export const useCodeMirror = (
  extensions: Extension[],
  options: Completion[],
  defaultCode?: string,
): { ref: (node: HTMLElement | null) => void } => {
  const [element, setElement] = useState<HTMLElement>();
  const viewRef = useRef<EditorView>();
  const completionConf = useMemo(() => new Compartment(), []);

  const ref = useCallback((node: HTMLElement | null) => {
    if (!node) return;

    setElement(node);
  }, []);

  useEffect(() => {
    if (!viewRef?.current) return;

    viewRef?.current.dispatch({
      effects: completionConf.reconfigure(
        autocompletion({
          override: [context => customCompletions(context, options)],
        }),
      ),
    });
  }, [completionConf, options]);

  useEffect(() => {
    if (!viewRef?.current) return;

    viewRef?.current.dispatch({
      changes: { from: 0, to: viewRef?.current.state.doc.length, insert: defaultCode || '' },
    });
  }, [defaultCode]);

  useEffect(() => {
    if (!element) return;

    const state = EditorState.create({
      extensions: [
        javascript(),
        completionConf.of(
          autocompletion({
            override: [context => customCompletions(context, options)],
          }),
        ),
        basicDark,
        ...extensions,
      ],
    });

    const view = new EditorView({
      state,
      parent: element,
    });
    viewRef.current = view;
    view.dispatch({
      changes: { from: 0, to: view.state.doc.length, insert: defaultCode || '' },
    });

    return () => view?.destroy();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [element, defaultCode]);

  return { ref };
};
