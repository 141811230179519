import { createContext, FC, useContext } from 'react';

import jwtDecode from 'jwt-decode';

import { AUTH_KEY } from '../../shared/constants';

import { CookieStorage } from './CookieStorage';

interface tokenInfo {
  [key: string]: string | boolean | number | null;
  'https://8base.com/visitor_id': string;
  // eslint-disable-next-line camelcase
  given_name: string;
  // eslint-disable-next-line camelcase
  family_name: string;
  nickname: string;
  name: string;
  picture: string;
  locale: string;
  // eslint-disable-next-line camelcase
  updated_at: string;
  email: string;
  // eslint-disable-next-line camelcase
  email_verified: boolean;
  iss: string;
  aud: string;
  iat: number;
  exp: number;
  sub: string;
  // eslint-disable-next-line camelcase
  at_hash: string;
  sid: string;
  nonce: string;
}

type ContextStorageOptions = {
  CookieStorage: CookieStorage;
  getTokenInfo: () => tokenInfo | undefined;
  getCookieStorage: () => Record<string, never>;
};

const CookieStoreContext = createContext<ContextStorageOptions>({} as ContextStorageOptions);

export const useCookieStorage = (): ContextStorageOptions => {
  const { CookieStorage: store, getCookieStorage, getTokenInfo } = useContext(CookieStoreContext);

  return {
    CookieStorage: store,
    getCookieStorage,
    getTokenInfo,
  };
};

export const CookieStoreProvider: FC = ({ children }) => {
  const cookieStorage = new CookieStorage();

  const getCookieStorage = () => {
    const stringItem = cookieStorage.getItem(AUTH_KEY);
    return stringItem ? JSON.parse(stringItem) : {};
  };

  const getTokenInfo = (): tokenInfo | undefined => {
    const { token } = getCookieStorage();
    if (!token) return;

    return jwtDecode(token);
  };

  return (
    <CookieStoreContext.Provider
      value={{ CookieStorage: cookieStorage, getTokenInfo, getCookieStorage }}
    >
      {children}
    </CookieStoreContext.Provider>
  );
};
