import React, { FC } from 'react';

import { Logout } from '@mui/icons-material';
import { Button, Dialog, DialogContentText, DialogTitle } from '@mui/material';

import { useDialogState } from '../../../providers';
import { MESSAGES } from '../../../shared/constants';
import { DASHBOARD_DIALOGS } from '../dialogsMap';
import { DialogActionsCenter, DialogContentWithCenterIcon } from 'src/dialogs/common/components';

const DIALOG_ID = DASHBOARD_DIALOGS.SESSION_CLOSE_DIALOG_ID;

export const SessionCloseDialog: FC = () => {
  const { isOpen } = useDialogState(DIALOG_ID);
  const onClose = () => window.location.reload();

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="md">
      <DialogTitle>{MESSAGES.dialogs.sessionEnd.title}</DialogTitle>
      <DialogContentWithCenterIcon>
        <Logout style={{ width: 46, height: 46 }} color="action" />
        <DialogContentText style={{ padding: 16, fontSize: 'large' }}>
          {MESSAGES.dialogs.sessionEnd.body}
        </DialogContentText>
      </DialogContentWithCenterIcon>
      <DialogActionsCenter>
        <Button variant="contained" color="primary" onClick={onClose}>
          {MESSAGES.dialogs.sessionEnd.confirm}
        </Button>
      </DialogActionsCenter>
    </Dialog>
  );
};
