export const DASHBOARD_DIALOGS = {
  ASSET_DELETE_DIALOG_ID: 'ASSET_DELETE_DIALOG_ID',
  CODE_EDITOR_DIALOG_ID: 'CODE_EDITOR_DIALOG_ID',
  CSS_EDITOR_DIALOG_ID: 'CSS_EDITOR_DIALOG_ID',
  CHECK_DSL_VERSION_DIALOG_ID: 'CHECK_DSL_VERSION_DIALOG_ID',
  NO_RESOURCES_WARNING_DIALOG_ID: 'NO_RESOURCES_WARNING_DIALOG_ID',
  PREVENT_DELETE_SYMBOL_DIALOG_ID: 'PREVENT_DELETE_SYMBOL_DIALOG_ID',
  NOT_AUTHTORIZED_DIALOG_ID: 'NOT_AUTHTORIZED_DIALOG_ID',
  PREVENT_DELETE_RESOURCE_DIALOG_ID: 'PREVENT_DELETE_RESOURCE_DIALOG_ID',
  DEPLOY_PROGRESS_DIALOG_ID: 'DEPLOY_PROGRESS_DIALOG',
  UNSAVED_CHANGES_DIALOG_ID: 'UNSAVED_CHANGES_DIALOG',
  MIGRATION_RESULT_DIALOG_ID: 'MIGRATION_RESULT_DIALOG',
  REFACTOR_FUNCTION_DIALOG_ID: 'REFACTOR_FUNCTION_DIALOG',
  PREVENT_BREAKING_DSL_DIALOG_ID: 'PREVENT_BREAKING_DSL_DIALOG',
  BROKEN_LOADED_DSL_DIALOG_ID: 'BROKEN_LOADED_DSL_DIALOG_ID',
  LOST_CONNECTION_DIALOG_ID: 'LOST_CONNECTION_DIALOG_ID',
  CODE_ENGINE_ERROR_DIALOG_ID: 'CODE_ENGINE_ERROR_DIALOG_ID',
  CONFIRM_DELETE_DIALOG_ID: 'CONFIRM_DELETE_DIALOG_ID',
  SESSION_CLOSE_DIALOG_ID: 'SESSION_CLOSE_DIALOG_ID',
} as const;
