import React from 'react';

import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { Provider as RollbarProvider } from '@rollbar/react';
import { DndProvider } from 'react-dnd';
import { TouchBackend } from 'react-dnd-touch-backend';
import { BrowserRouter } from 'react-router-dom';

import { API_STANDS, processEnv } from '@builder/utils';

import {
  ApolloProvider,
  AuthProvider,
  OverlayPositionProvider,
  ThemeProvider,
  ReduxProvider,
  EventEmitterProvider,
  DialogProvider,
  GqlSchemaProvider,
  DraftEngineProvider,
  DeployProvider,
} from 'src/providers';
import { DndDashboardLayer } from 'src/shared/dnd';

import { GlobalDialogs } from './dialogs';
import { CursorTooltipProvider, CookieStoreProvider } from './providers';
import { AnalyticsProvider } from './providers/AntalyticsProvider';
import { NotificationsProvider } from './providers/NotificationsProvider';
import { RootRoutes } from './routes';
import { getEnvironmentString, getSvgPatterns } from './shared/utils';

// https://github.com/emotion-js/emotion/issues/1105#issuecomment-557726922
// to suppress ssr errors
const emotionCache = createCache({
  key: 'app-builder',
  prepend: true,
});

emotionCache.compat = true;

const DND_PROVIDER_OPTIONS = {
  // NOTE: This is buggy due to the difference in
  // touchstart/touchend event propagation compared to mousedown/mouseup/click.
  enableMouseEvents: true,
  // A flag indicating whether to enable keyboard event processing
  enableKeyboardEvents: true,
  // Specifies the pixel distance moved before a drag is signaled.
  touchSlop: 4,
  // The amount in ms to delay processing for all events
  delay: 20,
  // The amount in ms to delay processing of touch events
  delayTouchStart: 0,
  // The amount in ms to delay processing of mouse events
  delayMouseStart: 0,
};

const rollbarConfig = {
  accessToken: processEnv.getRollbarAccessToken(),
  environment: getEnvironmentString(API_STANDS, processEnv.getServerURL()),
};

export const App = (): JSX.Element => {
  return (
    <>
      {getSvgPatterns()}
      <CookieStoreProvider>
        <CacheProvider value={emotionCache}>
          <RollbarProvider config={rollbarConfig}>
            <AuthProvider>
              <BrowserRouter>
                <DialogProvider>
                  <EventEmitterProvider>
                    <DraftEngineProvider>
                      <ReduxProvider>
                        <ApolloProvider>
                          <GqlSchemaProvider>
                            <DeployProvider>
                              <ThemeProvider>
                                <AnalyticsProvider>
                                  <OverlayPositionProvider>
                                    <CursorTooltipProvider>
                                      {/* WARN:
                                seems-like dnd-provider should be a child
                                of whatever provider is the last to handle dnd-references;
                                otherwise it won't be able to register elements after external updates,
                                eg. undo-redo could make dnd-provider loose references
                               */}
                                      <DndProvider
                                        backend={TouchBackend}
                                        options={DND_PROVIDER_OPTIONS}
                                      >
                                        <RootRoutes />
                                        <GlobalDialogs />
                                        <DndDashboardLayer />
                                        <NotificationsProvider />
                                      </DndProvider>
                                    </CursorTooltipProvider>
                                  </OverlayPositionProvider>
                                </AnalyticsProvider>
                              </ThemeProvider>
                            </DeployProvider>
                          </GqlSchemaProvider>
                        </ApolloProvider>
                      </ReduxProvider>
                    </DraftEngineProvider>
                  </EventEmitterProvider>
                </DialogProvider>
              </BrowserRouter>
            </AuthProvider>
          </RollbarProvider>
        </CacheProvider>
      </CookieStoreProvider>
    </>
  );
};
