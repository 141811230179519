import { EventPayload } from '@8base-private/event-handler';
import { AnyAction as ReduxAction } from 'redux';

import { AppConfiguration, NodeDSL } from '@builder/schemas';

import { DASHBOARD_EVENTS } from 'src/store/common';

import { ActionScopeStrategy } from './ActionScopeStrategy';
import { ComponentActionsStrategy } from './ComponentActionsStrategy';
import { DefaultStrategy } from './DefaultStrategy';
import { RouteCloneStrategy } from './RouteCloneStrategy';
import { StateConvertStrategy } from './StateConvertStrategy';
import { SystemAndLayoutsStrategy } from './SystemAndLayoutsStrategy';
import { UndoRedoStrategy } from './UndoRedoStrategy';

class ActionScopeContext {
  private strategy: ActionScopeStrategy;

  constructor(strategy: ActionScopeStrategy) {
    this.strategy = strategy;
  }

  execute(
    currentRouteNode: NodeDSL,
    eventPayload: EventPayload,
    action: ReduxAction,
    currentConfiguration: AppConfiguration,
    previousConfiguration: AppConfiguration,
  ) {
    return this.strategy.execute(
      currentRouteNode,
      eventPayload,
      action,
      currentConfiguration,
      previousConfiguration,
    );
  }
}

// use context to call a specific strategy instance based on the case
export function getActionScope(
  currentRouteNode: NodeDSL,
  eventPayload: EventPayload,
  action: ReduxAction,
  currentConfiguration: AppConfiguration,
  previousConfiguration: AppConfiguration,
): { eventPayload: EventPayload; newEventsToPush: EventPayload[] } {
  const strategy = getStrategy(currentRouteNode, action);
  const context = new ActionScopeContext(strategy);
  const result = context.execute(
    currentRouteNode,
    eventPayload,
    action,
    currentConfiguration,
    previousConfiguration,
  );

  if (result === null) {
    throw new Error('ActionScopeStrategy returned null');
  }

  return result;
}

function getStrategy(currentRouteNode: NodeDSL, action: ReduxAction): ActionScopeStrategy {
  const { system, path } = currentRouteNode.props as { system?: boolean; path?: string };

  if (action.type === DASHBOARD_EVENTS.applyEvents) {
    return new UndoRedoStrategy();
  }

  if (system && path?.includes('/__layouts/__')) {
    return new SystemAndLayoutsStrategy();
  }

  if (action.type.includes('component') || action.type === DASHBOARD_EVENTS.copyBufferApply) {
    return new ComponentActionsStrategy();
  }

  if (action.type === DASHBOARD_EVENTS.routeClone) {
    // create an instance of the appropriate class
    return new RouteCloneStrategy();
  }

  if (action.type === DASHBOARD_EVENTS.stateConvert) {
    return new StateConvertStrategy();
  }

  // create an instance of the appropriate class
  return new DefaultStrategy();
}
