import React, { useCallback, useRef, useState } from 'react';

import styled from '@emotion/styled';
import { InputAdornment, Menu, MenuItem } from '@mui/material';
import { equals } from 'ramda';

import { AppRuntimeStates } from '@builder/app-engine/src';
import { JSInjection, PossibleUnitTypes, POSSIBLE_UNIT_TYPES } from '@builder/schemas';

import { AutoCompleteOptions } from '../../types';
import { getCurrentValue, getInitialUnit, valueEndsWithUnit } from '../../utils';
import { JsCodeInjectionInput } from '../common/JsCodeInjectionInput';
import { AutocompleteOption, TextField } from 'src/shared/components';
import { EVENT_KEYS } from 'src/shared/constants';
import { ACTIVEPROP } from 'src/shared/constants/FxButton';
import { useDoubleClickEditText } from 'src/shared/hooks';

type ValueType = string | JSInjection | undefined;

export type TextViewEditorProps = {
  targetNodeId?: string;
  name?: string;
  label?: string;
  icon?: string;
  isTextIcon?: boolean;
  propValue: ValueType;
  error?: boolean;
  helperText?: React.ReactNode;
  onChangePropValue: (propValue: ValueType, eventType?: string) => void;
  onKeyDown?: React.KeyboardEventHandler<HTMLDivElement>;
  nodeID?: string;
  possibleUnits?: PossibleUnitTypes[];
  skipDebounce?: boolean;
  placeholder?: string;
  multiline?: boolean;
  autoFocus?: boolean;
  'data-test'?: string;
  'data-enum'?: string;
  'data-key'?: string;
  hideExpandableButton?: boolean;
  showFx?: boolean;
  fxDefaultEnabled?: string;
  options?: Array<AutocompleteOption<string | number>>;
  localStates?: AppRuntimeStates | undefined;
  customStateOptions?: AutoCompleteOptions[];
  updateOnBlur?: boolean;
};

const UnitWrapper = styled.span`
  color: ${({ theme }) => theme.palette.grey[400]};
  cursor: pointer;
`;

const changePropsValue = (
  unit: PossibleUnitTypes | undefined,
  value: ValueType,
  callback: (propValue: ValueType, eventType?: string) => void,
  eventType?: string,
) => {
  if (!unit || unit.type === POSSIBLE_UNIT_TYPES.value) {
    return callback(value, eventType);
  }

  if (value) {
    if (value.endsWith(unit.value)) {
      return callback(value, eventType);
    }

    const newValue = `${value}${unit.value}`;
    return callback(newValue, eventType);
  }

  callback(undefined);
};

export const TextViewEditor: React.FC<TextViewEditorProps> = ({
  targetNodeId,
  name,
  label,
  icon,
  isTextIcon,
  propValue,
  onChangePropValue,
  nodeID,
  possibleUnits,
  skipDebounce = false,
  placeholder,
  multiline,
  error,
  helperText,
  autoFocus,
  onKeyDown,
  'data-test': dataTest,
  'data-enum': dataEnum,
  'data-key': dataKey,
  hideExpandableButton,
  showFx = true,
  fxDefaultEnabled = ACTIVEPROP.literal,
  options,
  customStateOptions,
  updateOnBlur = false,
}) => {
  const [currentUnit, setCurrentUnit] = useState<PossibleUnitTypes | undefined>(
    getInitialUnit(propValue, possibleUnits),
  );
  const [anchorEl, setAnchorEl] = useState<HTMLSpanElement | null>(null);
  const currentValue = getCurrentValue(propValue, currentUnit);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [onChangeDebounceId, setonChangeDebounceId] = useState<NodeJS.Timeout>();

  const debounceOnChange = (cb: () => void) => {
    if (onChangeDebounceId) {
      clearInterval(onChangeDebounceId);
    }

    const timerId = setTimeout(cb, 300);
    setonChangeDebounceId(timerId);
  };

  useDoubleClickEditText<React.MutableRefObject<HTMLInputElement | null>>({
    targetNodeId: targetNodeId as string,
    inputLabel: label as string,
    ref: inputRef,
  });
  const handleUpdatePropChange = useCallback(
    (value: ValueType, eventType?: string) => {
      if (!eventType) return;

      const newPossibleUnit = valueEndsWithUnit(value, possibleUnits);

      if ((updateOnBlur && eventType === 'blur') || eventType === 'change') {
        if (
          currentUnit?.type !== POSSIBLE_UNIT_TYPES.value &&
          newPossibleUnit &&
          !equals(newPossibleUnit, currentUnit)
        ) {
          setCurrentUnit(newPossibleUnit);
          return onChangePropValue(value, eventType);
        }

        changePropsValue(currentUnit, value, onChangePropValue, eventType);
      }
    },
    [currentUnit, onChangePropValue, possibleUnits, updateOnBlur],
  );

  const openMenu = useCallback((event: React.MouseEvent<HTMLSpanElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const closeMenu = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const changeUnit = (unit: PossibleUnitTypes) => () => {
    if (unit === currentUnit) {
      return setAnchorEl(null);
    }

    setCurrentUnit(unit);
    setAnchorEl(null);

    const valueWithoutUnit = getCurrentValue(propValue, currentUnit);

    if (unit?.type === POSSIBLE_UNIT_TYPES.value) {
      return onChangePropValue(valueWithoutUnit);
    }

    changePropsValue(unit, valueWithoutUnit, onChangePropValue);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    switch (event.key) {
      case EVENT_KEYS.ARROW_DOWN:
      case EVENT_KEYS.ARROW_UP: {
        if (!currentValue) {
          break;
        }

        const direction = event.key === EVENT_KEYS.ARROW_UP ? 1 : -1;
        const transformedValue = Number(currentValue);

        if (!isNaN(transformedValue)) {
          changePropsValue(currentUnit, String(transformedValue + direction), onChangePropValue);
        }
      }
    }

    onKeyDown?.(event);
  };

  return (
    <>
      <JsCodeInjectionInput
        label={label ?? ''}
        onChangePropValue={value => {
          debounceOnChange(() => {
            handleUpdatePropChange(value, 'change');
          });
        }}
        nodeID={targetNodeId}
        propValue={currentValue}
        data-test={dataTest}
        fxDefaultEnabled={fxDefaultEnabled}
        customStateOptions={customStateOptions}
        showFx={showFx}
      >
        {({ isFxEnabled, enableFx, nonJsCodePropValue }) => (
          <TextField
            inputRef={inputRef}
            fullWidth
            variant="outlined"
            isFxEnabled={isFxEnabled}
            enableFx={enableFx}
            size="small"
            name={name}
            showFx={showFx}
            label={label}
            icon={icon}
            error={error}
            helperText={helperText}
            isTextIcon={isTextIcon}
            placeholder={placeholder}
            autoFocus={autoFocus}
            multiline={multiline}
            value={currentValue ?? nonJsCodePropValue}
            skipDebounce={skipDebounce}
            onKeyDown={handleKeyDown}
            onDebounceChange={event => handleUpdatePropChange(event, 'change')}
            onChange={event =>
              handleUpdatePropChange(event?.currentTarget?.value ?? event, 'change')
            }
            onBlur={event => {
              if (updateOnBlur) {
                debounceOnChange(() => {
                  handleUpdatePropChange(event?.currentTarget?.value ?? event, 'blur');
                });
              }
            }}
            data-test={dataTest}
            data-enum={dataEnum}
            data-key={dataKey}
            absoluteEndAdornmentPosition={!currentUnit}
            InputProps={{
              endAdornment: currentUnit && (
                <InputAdornment position="end">
                  <UnitWrapper onClick={openMenu}>
                    {!currentUnit.value ? '-' : currentUnit.value}
                  </UnitWrapper>
                </InputAdornment>
              ),
            }}
          />
        )}
      </JsCodeInjectionInput>
      {possibleUnits && possibleUnits.length > 1 && (
        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={closeMenu}>
          {possibleUnits.map(el => (
            <MenuItem
              key={el.value}
              onClick={changeUnit(el)}
              selected={el.value === currentUnit?.value}
            >
              {!el.value ? 'none' : el.value}
            </MenuItem>
          ))}
        </Menu>
      )}
    </>
  );
};
